<template>

<div class="sells">
    <van-tabs v-model:active="active" @click="onChange"   border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs">
        <van-tab title="在卖" name="0">
            <div class="now-list">
                          <van-list
        v-model:loading="nowloading"
        :finished="nowfinished"
        :finished-text="nowList.length > 5 ? '没有更多了':''"
        @load="nowonLoad"
        >
              <sell :sellData="nowList" type='zm' @updata="updata()"></sell>
                </van-list>
            </div>

        </van-tab>
        <van-tab title="草稿"  name="1">
            <div class="now-list">
                          <van-list
        v-model:loading="draftloading"
        :finished="draftfinished"
        :finished-text="draftList.length > 5 ? '没有更多了':''"
        @load="draftonLoad"
        >
                <sell :sellData="draftList" type='cg' @updata="updata()"></sell>
                  </van-list>
            </div>
        </van-tab>
        <van-tab title="已下架" name="2">
            <div class="now-list">
                          <van-list
        v-model:loading="offloading"
        :finished="offfinished"
       :finished-text="offList.length > 5 ? '没有更多了':''"
        @load="offonLoad"
        >
                <sell :sellData="offList" type='xj' @updata="updata()"></sell>
                  </van-list>
            </div>
        </van-tab>
        <van-tab title="已关闭" name="3">
            <div class="now-list">
                          <van-list
        v-model:loading="closeloading"
        :finished="closefinished"
       :finished-text="closeList.length > 5 ? '没有更多了':''"
        @load="closeonLoad"
        >
                <sell :sellData="closeList" type='gb' @updata="updata()"></sell>
                  </van-list>
            </div>
        </van-tab>
    </van-tabs>
</div>
</template>

<script>
import UserInfo from "components/userInfo";
import sell from "../../../components/sell"
export default {
  name: "user.sell",
  data(){
    return{
 active:0,
        nowList:[],
        nowpage:1,
        nowLoad:true,
        draftList:[],
        draftpage:1,
        draftLoad:true,
        closeList:[],
        closepage:1,
        closeLoad:true,
        offList:[],
        offpage:1,
        offLoad:true,
        nowloading:false,
        nowfinished:true,
        draftloading:false,
        draftfinished:true,
        offloading:false,
        offfinished:true,
        closeloading:false,
        closefinished:true,
    }
  },

    created() {
       if (this.$store.getters.acts) {
        this.active = this.$store.getters.acts
         this.$store.commit('M_ACTS','0')
      }
      if(this.active == '0'){
       this.getnowList()
      }else if(this.active == '1'){
        this.getdraftList()
      }else if(this.active == '2'){
         this.getoffList()
      }else if(this.active == '3'){
         this.getcloseList()
      }
      
      
  },
  
  methods:{
     //在卖列表
    getnowList(){
        let url = this.$api.Flea + '?pageSize=5&status=1&category_id=0&page=' + this.nowpage +'&is_user=' + this.userInfo.id
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
              this.nowList = this.nowList.concat(res.data.data)
                this.nowpage = this.nowpage + 1
                this.nowloading = false
                this.nowfinished = false
            }else{
              this.nowLoad = false
              this.nowfinished = true
            }
          }
        })
      },
      //草稿列表
      getdraftList(){
        let url = this.$api.Flea + '?pageSize=5&status=0&category_id=0&page=' + this.draftpage +'&is_user=' + this.userInfo.id
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
                 this.draftList = this.draftList.concat(res.data.data),
                 this.draftpage = this.draftpage + 1
                 this.draftloading = false
                this.draftfinished = false
                console.log(this.draftList)
            }else{
                 this.draftLoad = false
                  this.draftfinished = true
            }
          }
        })
      },
      //下架列表
      getoffList(){
        let url = this.$api.Flea + '?pageSize=5&status=2&category_id=0&page=' + this.offpage +'&is_user=' + this.userInfo.id
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
                this.offList = this.offList.concat(res.data.data),
                this.offpage = this.offpage + 1
                   this.offloading = false
                this.offfinished = false
            }else{
                this.offLoad = false
                  this.offfinished = true
            }
          }
        })
      },
       //关闭列表
      getcloseList(){
        let url = this.$api.Flea + '?pageSize=5&status=-1&category_id=0&page=' + this.closepage +'&is_user=' + this.userInfo.id
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
                this.closeList = this.closeList.concat(res.data.data),
                this.closepage = this.closepage + 1
                   this.closeloading = false
                this.closefinished = false
            }else{
                this.closeLoad = false
                  this.closefinished = true
            }
          }
        })
      },
    onChange(event) {
        this.active = event
        if(this.active == 0){
          this.nowList = []
            this.nowpage = 1
            this.nowLoad = true
            this.getnowList()
        }else if(this.active == 1){
 this.draftList = []
            this.draftpage = 1
            this.draftLoad = true
            this.getdraftList()
        }else if(this.active == 2){
this.offList = []
            this.offpage = 1
            this.offLoad = true
          this.getoffList()
        }else if(this.active == 3){
this.closeList = []
            this.closepage = 1
            this.closeLoad = true
          this.getcloseList()
        }
        },
        listupdata(){
            this.nowList = []
            this.nowpage = 1
            this.nowLoad = true
            this.draftList = []
            this.draftpage = 1
            this.draftLoad = true
            this.offList = []
            this.offpage = 1
            this.offLoad = true 
            this.closeList = []
            this.closepage = 1
            this.closeLoad = true
          this.getnowList()
          this.getdraftList()
          this.getoffList()
          this.getcloseList()
        },
         nowonLoad(){
        if(this.nowLoad){
          this.getnowList()
        }
      },
         draftonLoad(){
        if(this.draftLoad){
          this.getdraftList()
        }
      },
         offonLoad(){
        if(this.offLoad){
          this.getoffList()
        }
      },
         closeonLoad(){
        if(this.closeLoad){
          this.getcloseList()
        }
      },
      updata(){
        this.listupdata()
      }
  },
  components:{
    UserInfo,
    sell
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/sell/sell.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.sells .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 84/$r;
    z-index: 99;
  }
.sells .van-tabs__line {
    width: 0.8533333333rem !important;
    height: 0.128rem;
    background: #FF6B29;
    border-radius: 0.064rem;
}
</style>