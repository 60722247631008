<template>
<div class="sell">
   <NoData v-if="sellDatalist == '' || sellDatalist.length == 0" :bottomBg="true"></NoData>
    <div class="now-item" @click="gomarket(item.id,item.status)"  v-for="(item,index) in sellDatalist" :key="index">
        <div class="now-item-t">
            <img class="now-item-t-l" :src="item.picture && item.picture[0]" />
            <div class="now-item-t-r">
                <div class="now-item-t-r-t white-space">{{item.title}}</div>
                <div class="now-item-t-r-c vertical-center"><span>{{item.credit}}</span>积分</div>
                <div class="now-item-t-r-b" v-if="type == 'zm' || type == 'xj'">{{item.create_time}}｜浏览{{$handle.tranNumber( item.browse_num,3)}} · 收藏{{$handle.tranNumber( item.collect_num,3)}}</div>
                <div class="now-item-t-r-b" v-if="type == 'cg'">可以直接发布了呦～</div>
            </div>
        </div>
        <van-divider />
        <div class="now-item-b">
             <div class="now-item-b-l vertical-center" v-if="type == 'gb' && activeid != item.id" @click.stop="close(item.id)">
               关闭原因
                        <img  src="~assets/images/index/btn-b.png"  class="now-item-b-l-img" alt=""/>
                        </div>
                         <div class="now-item-b-l vertical-center" v-if="type == 'gb' && activeid == item.id" @click.stop="close(0)">
               关闭原因
                        <img  src="~assets/images/index/btn-t.png"  class="now-item-b-l-img" alt=""/>
                        </div>
            <div class="now-item-b-r">
                <div class="now-item-b-r-btn" @click.stop="down(item.id,'2',item.category_id)"  v-if="type == 'zm'">下架</div>
                <div class="now-item-b-r-btn" @click.stop="del(item.id,item.category_id)" v-if="type != 'zm'">删除</div>
                <div class="now-item-b-r-btn-a" @click.stop="goCreate(item.id)" v-if="(type == 'zm' || type == 'cg') && item.stock != 0">编辑</div>
                <div class="now-item-b-r-btn-a now-item-b-r-btn-a-gray" @click.stop="goCreates()" v-if="(type == 'zm' || type == 'cg') && item.stock == 0">编辑</div>
                   <div class="now-item-b-r-btn-a" @click.stop="again(item.id,'1',item.category_id,'0')" v-if="type == 'cg'">发布</div>
                <div class="now-item-b-r-btn-a" @click.stop="again(item.id,'1',item.category_id,'1')" v-if="type == 'xj'">重新上架</div>
                <div class="now-item-b-r-btn-a" v-if="type == 'gb'">查看详情</div>
            </div>
        </div>
        <div class="now-item-f" v-if="type == 'gb' && activeid == item.id">
          <img  src="~assets/images/index/btn-err.png"  class="now-item-b-l-img" alt=""/>
            {{item.reason}}
        </div>
    </div>
</div>

</template>

<script>
import UserInfo from "components/userInfo";
import NoData from "components/NoData";
export default {
  name: "sell",
  components: {UserInfo,NoData},
  props: ['sellData','type'],
  data() {
    return {
      sellDatalist: [],
      activeid:0
    }
  },
  created() {
  
   
  },
  methods: {
//跳转跳蚤详情
gomarket(id,status){
      if(status == 1){
      this.$store.commit('M_ACTS','0')
      }else if(status == 0){
      this.$store.commit('M_ACTS','1')
      }else if(status == 2){
      this.$store.commit('M_ACTS','2')
      }else if(status == -1){
      this.$store.commit('M_ACTS','3')
      }
     this.$router.push({path: '/community/market-show',query: {id: id}})
  },
  close(id){
    this.activeid = id
  },
  //下架
  down(id,status,categoryid){
    let url = this.$api.communityFlea 
    let data = {
      id:id,
      status:status,
      category_id:categoryid
    }
   this.$http.put(url, data, true).then(res => {
      if(res.data.success){
        this.$toast('下架成功')
        this.$emit('updata', );
      }else{
          this.$toast(res.data.message)
      }
    }).catch(error =>{
      if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
    })
  },
  //上架 
  again(id,status,categoryid,num){
    if (num == 0){
      let url = this.$api.fleaPublish + '/' + id
      this.$http.put(url, {}, true).then(res => {
        if(res.data.success){
            this.$toast('发布成功')
          this.$emit('updata', );
        }else{
          this.$toast(res.data.message)
        }
      }).catch(error =>{
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    }else{
      let url = this.$api.communityFlea
      let data = {
        id:id,
        status:status,
        category_id:categoryid
      }
      this.$http.put(url, data, true).then(res => {
        if(res.data.success){
            this.$toast('上架成功')
          this.$emit('updata', );
        }else{
          this.$toast(res.data.message)
        }
      }).catch(error =>{
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    }

  },
  //删除
  del(id){
    let url = this.$api.communityFlea + '/' + id
    this.$http.del(url, {}, true).then(res => {
      if(res.data.success){
        this.$toast('删除成功')
        this.$emit('updata', );
      }
    })
  },
  //编辑
  goCreate(id){
    this.$router.push({path: '/community/market-create',query: {id: id}})
  },
  goCreates(id){
    
  },
  },
  watch: {
    sellData(val, oldval) {
      this.sellDatalist = val
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;

/* components/sell/sell.wxss */
.sell{
  background: #f7f7f7;
}
.sell .now-item {
  padding: 30/$r;
  box-sizing: border-box;
  background: #FFF;
  margin-bottom: 16/$r;

  .now-item-t {
    display: flex;

    .now-item-t-l {
      width: 160/$r;
      height: 160/$r;
      border-radius: 10/$r;
      margin-right: 36/$r;
    }

    .now-item-t-r {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16/$r 0;
      width: 490/$r;

      .now-item-t-r-t {
        font-size: 26/$r;
        font-weight: 400;
        color: #313A43;
      }

      .now-item-t-r-s {
        font-size: 24/$r;
        font-weight: 400;
        color: #F01414;
        justify-content: flex-end;

        .now-item-t-r-s-t {
          font-size: 24/$r;
          font-weight: 400;
          color: #93999F;
          margin-right: 15/$r;
        }

        span {
          font-size: 32/$r;
        }
      }

      .now-item-t-r-c {
        font-size: 24/$r;
        font-weight: 400;
        color: #F01414;

        span {
          font-size: 32/$r;
          font-weight: 500;
          color: #F01414;
        }
      }

      .now-item-t-r-b {
        font-size: 25/$r;
        font-weight: 400;
        color: #93999F;
      }
    }
  }

  .now-item-b {
    display: flex;

    .now-item-b-l {
      display: flex;
      font-size: 24/$r;
      font-weight: 400;
      color: #93999F;

      .now-item-b-l-img {
        width: 26/$r;
        height: 26/$r;
        margin-right: 0/$r;
        margin-left: 18/$r;
      }
    }

    .now-item-b-r {
      margin-left: auto;
      display: flex;

      .now-item-b-r-btn {
        width: 146/$r;
        height: 56/$r;
        border: 2/$r solid #B7BBBF;
        border-radius: 28/$r;
        font-size: 24/$r;
        font-weight: 400;
        color: #93999F;
        text-align: center;
        line-height: 56/$r;
        box-sizing: border-box;
      }

      .now-item-b-r-btn-a {
        width: 146/$r;
        height: 56/$r;
        background: #FF6B29;
        border-radius: 28/$r;
        font-size: 24/$r;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 56/$r;
        margin-left: 30/$r;
      }
    }
  }
}
.now-item-b-r-btn-a-gray{
    background: #999!important;
}
.now-item-f{
      font-size: 24/$r;
font-weight: 400;
color: #333333;
line-height: 50/$r;
margin-top: 10/$r;
}
.now-item-f .now-item-b-l-img{
    width: 26/$r;
    height: 26/$r;
   margin-right: 11/$r;
   vertical-align: middle;
}
</style>